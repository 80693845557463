<template>
  <transition name="exit-intent-fade">
    <div
      v-show="canShowPopupPromo && showModal"
      class="modal-mask"
      role="dialog"
      style="z-index: 225;"
      @click="closeModal"
    >
      <div class="modal-wrapper">
        <div class="modal-container modal-cta-small clickable-modal">
          <button
            type="button"
            class="modal-close"
            aria-label="Close"
            @click="closeModal"
          >
            Close
          </button>
          <div @click.prevent="modalClick">
            <div class="image-top-to-bottom-left">
              <img
                v-lazy-load data-src="~/assets/images/club-hand-bottom-gray.svg"
                alt="Hand holding wine glass by stem"
                role="presentation"
              />
            </div>
            <span v-if="constance.P1_PERCENTAGE_ICON > 0" class="percent-ico">
              {{ constance.P1_PERCENTAGE_ICON }}%
            </span>
            <span
              v-else-if="constance.P1_FIXED_AMOUNT_ICON > 0"
              class="percent-ico"
              :style="{
                'font-size':
                  constance.P1_FIXED_AMOUNT_ICON < 100 ? '32px' : '28px',
              }"
            >
              ${{ constance.P1_FIXED_AMOUNT_ICON }}
            </span>
            <div class="container-top-to-bottom-right">
              <p class="h4 container-width" v-html="constance.P1_HEAD_LINE" />
              <p v-html="constance.P1_BODY_TEXT" />
            </div>
            <button class="btn btn-primary" v-html="constance.P1_BUTTON_TEXT" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import isMobile from 'ismobilejs'

const MS_PER_DAY = 86400000

export default {
  name: 'PopupPromo',
  props: {
    storageKey: {
      type: String,
      default: 'disablePopupPromo',
    },
  },
  data() {
    return {
      forbidden: true,
      showModal: false,
      attempted: false,
    }
  },
  computed: {
    ...mapGetters([
      'toggleCart',
      'settingsVars',
      'constance',
      'inMobileApp',
      'pptoToastModalOpen',
    ]),
    routeDisabled() {
      return (
        this.$route.path.includes('checkout') ||
        this.$route.path.includes('wine-club/gifting') ||
        this.$route.path.includes('giftcard')
      )
    },
    canShowPopupPromo() {
      return (
        this.constance.P1_POPUP_PROMO_ENABLED === 'True' &&
        !this.inMobileApp &&
        !this.routeDisabled &&
        !this.pptoToastModalOpen &&
        !this.forbidden &&
        !this.toggleCart &&
        this.checkEnableDate &&
        this.checkURLParam &&
        this.constance.P1_BODY_TEXT &&
        this.constance.P1_HEAD_LINE &&
        this.constance.P1_BUTTON_TEXT &&
        (this.constance.P1_PERCENTAGE_ICON ||
          this.constance.P1_FIXED_AMOUNT_ICON)
      )
    },
    checkEnableDate() {
      if (!this.constance.P1_ENABLE_START || !this.constance.P1_ENABLE_END)
        return true

      // Manipulate the start date to make moment use an inclusive start with an exclusive
      // end. If we upgrade to moment >= 2.13.0 then we can use inclusion values:
      // https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
      // eg moment('2016-10-30').isBetween('2016-10-30', '2016-12-30', undefined, '[)')
      const start = this.$moment(this.constance.P1_ENABLE_START).subtract(
        1,
        'days'
      )
      return this.$moment().isBetween(start, this.constance.P1_ENABLE_END)
    },
    eventExtraData() {
      return {
        P1_POPUP_PROMO_ENABLED: this.constance.P1_POPUP_PROMO_ENABLED,
        P1_MIN_INITIAL_SUPPRESSION_TIME: this.constance
          .P1_MIN_INITIAL_SUPPRESSION_TIME,
        P1_MIN_NUMBER_OF_DAYS_SUPRESSION: this.constance
          .P1_MIN_NUMBER_OF_DAYS_SUPRESSION,
        P1_LOGGING: this.constance.P1_LOGGING,
        P1_ENABLE_START: this.constance.P1_ENABLE_START,
        P1_ENABLE_END: this.constance.P1_ENABLE_END,
        P1_HEAD_LINE: this.constance.P1_HEAD_LINE,
        P1_BODY_TEXT: this.constance.P1_BODY_TEXT,
        P1_PERCENTAGE_ICON: parseInt(this.constance.P1_PERCENTAGE_ICON),
        P1_FIXED_AMOUNT_ICON: parseInt(this.constance.P1_FIXED_AMOUNT_ICON),
        P1_BUTTON_TEXT: this.constance.P1_BUTTON_TEXT,
        P1_CLICK_REDIRECT_URL: this.constance.P1_CLICK_REDIRECT_URL,
        P1_URL: this.constance.P1_URL,
        P1_URL_PARAMETERS: this.constance.P1_URL_PARAMETERS,
      }
    },
    checkURLParam() {
      if (!this.constance.P1_URL_PARAMETERS) return true
      if (process.client) {
        const [key, value] = this.constance.P1_URL_PARAMETERS.split('=')
        return key in this.$route.query && this.$route.query[key] === value
      }
      return true
    },
  },
  watch: {
    $route(to, from) {
      this.checkURL()
    },
  },
  mounted() {
    // If enabled, log all variables related to showing exit intent.
    this.logInitialConditionVariables()

    // Making openModal action global for use by other third parties.
    window.openPopupPromoModal = this.openModal
    this.checkURL()
  },
  unmounted() {
    this.cleanEventListeners()
  },
  methods: {
    closeModal() {
      this.showModal = false
      // make sure the modal doesn't have another opportunity to show
      this.forbidden = true
      const current = new Date().getTime()
      localStorage.setItem(this.storageKey, JSON.stringify(current))
      this.$emit('close-popup-promo')
    },
    initiateModal() {
      // raising the modal up is forbidden before initialDelay ms
      // on the site, and for disableDays after previously seeing popup
      setTimeout(() => {
        this.forbidden = this.localStorageDisabled()
        this.openModal()
      }, Math.trunc(parseFloat(this.constance.P1_MIN_INITIAL_SUPPRESSION_TIME) * 1000))
      this.attempted = true
    },
    checkURL() {
      // Checks P1_URL config, if set verifies that the current path qualifies
      // attempts to initiate ( mount ) the modal only if the current page
      // permits it.
      if (!this.attempted && !this.constance.P1_URL) this.initiateModal()
      if (
        !this.attempted &&
        this.$route.fullPath.includes(this.constance.P1_URL)
      ) {
        this.initiateModal()
      }
    },
    logInitialConditionVariables() {
      if (this.constance.P1_LOGGING === 'True') {
        /* eslint-disable no-console */
        console.log('Popup Promo intial settings: \n')
        console.log({
          localStorageDisabled: this.localStorageDisabled(),
          isMobile: this.isMobile(),
          extraData: this.eventExtraData,
        })
        /* eslint-enable */
      }
    },
    openModal() {
      // If we cannot open modal do not make any additional attempts.
      if (!this.canShowPopupPromo) {
        if (this.forbidden && this.localStorageDisabled()) {
          this.cleanEventListeners()
        }
        return
      }
      this.showModal = true
      this.$emit('show-popup-promo')
      this.cleanEventListeners()
    },
    localStorageDisabled() {
      if (localStorage.getItem(this.storageKey)) {
        const old = JSON.parse(localStorage.getItem(this.storageKey))
        const current = new Date().getTime()

        return !(
          current - old >
          parseInt(this.constance.P1_MIN_NUMBER_OF_DAYS_SUPRESSION) * MS_PER_DAY
        )
      } else return false
    },
    modalClick() {
      const current = new Date().getTime()
      localStorage.setItem(this.storageKey, JSON.stringify(current))
      const redirectUrl = this.constance.P1_CLICK_REDIRECT_URL || '/store'
      return this.$router.push(redirectUrl)
    },
    isMobile() {
      const { req: request } = this.$nuxt.context
      return isMobile(
        request ? request.headers['user-agent'] : window.navigator
      ).phone
    },
    cleanEventListeners() {},
  },
}
</script>

<style scoped>
.clickable-modal {
  cursor: pointer;
}
</style>
